<template>
    <div class="mx-4 my-4 md:mx-8">
      <Breadcrumb :links="breadcrumb" />
      <br />
      <h1 class="text-3xl mb-5">Histórico Saldo</h1>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Anterior
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Sobre
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Data
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id">
                    <td class="text-sm font-semibold text-green-800 2xl:text-base px-2 py-3 whitespace-nowrap">
                      {{ item.saldo }}
                    </td>
                    <td class="text-sm font-semibold text-red-800 2xl:text-base px-2 py-3 whitespace-nowrap">
                      {{ item.saldoAnterior }}
                    </td>
                    <td class="text-sm font-normal 2xl:text-base px-2 py-3 whitespace-nowrap">
                      {{ item.sobre ? item.sobre : "" }}
                    </td>
                    <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                      {{`${moment(item.data).format("DD/MM/YYYY HH:mm")}`}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import Breadcrumb from "../../components/Breadcrumbs.vue";
  
  export default {
    components: {
      Breadcrumb,
    },
    data() {
      return {
        moment: moment,
        breadcrumb: [{ url: "/saldo", value: "Histórico Saldo" }],
        list: [],
        busca: "",
        total: 0,
      };
    },
    methods: {
      async start() {
        const req = await this.$http.post("/v1/users/list-historico", { all: true });
        console.log("req.data.data", req.data.data);
        this.list = req.data.data;
        this.total = req.data.total;
      },
    },
    async beforeMount() {
      this.start();
    },
  };
  </script>
  